<script setup lang="ts">
import { PlayCircleOutlined } from '@ant-design/icons-vue'
import { type acoountMenuType } from './type'
import { useAuthStore } from '~~/stores/auth'
import { useUserStore } from '~~/stores/user'

const route = useRoute()
const showSignin = ref<'desktop' | 'sidebar' | undefined>()
const currentAccountMenu = ref([route?.name])
const { isMobileOrTablet } = useDevice()

const userStore = useUserStore()
const userDetail = computed(() => userStore.state.userDetail)

const authStore = useAuthStore()
const isLoggedIn = computed(() => authStore.state.isLoggedIn)
const user = computed(() => authStore.state.user)

const accountMenu = ref<acoountMenuType[]>([
  {
    key: 'profil',
    label: 'Profil Saya',
    path: '/user'
  },
  {
    key: 'studi-saya',
    label: 'Studi Saya',
    path: '/user/studi-saya'
  },
  {
    key: 'wishlist',
    label: 'Wishlist',
    path: '/user/wishlist'
  },
  {
    key: 'playlist',
    label: 'Playlist',
    path: '/partnerplaylist'
  },
  {
    key: 'riwayat-transaksi',
    label: 'Riwayat Transaksi',
    path: '/user/riwayat-transaksi'
  }
])

if (isLoggedIn.value && isEmpty(user.value)) {
  authStore.logout()
}
</script>

<template>
  <header class="kg-navbar">
    <div class="container mx-auto">
      <!-- Mobile navbar -->
      <MoleculesNavbarMobile
        v-if="isMobileOrTablet"
        @show-signin="showSignin = 'sidebar'"
      />

      <nav v-else>
        <a-row
          align="middle"
          justify="space-between"
          :class="isLoggedIn ? 'gap-4' : 'gap-0'"
        >
          <a-col :span="3">
            <NuxtLink to="/" class="kg-navbar__logo">
              <nuxt-img
                src="/images/logo-kognisi-desktop.webp"
                alt="logo-kognisi"
                loading="lazy"
                format="webp"
                size="sm:129px md:142px"
                quality="70"
                preload
              />
            </NuxtLink>
          </a-col>
          <!-- <a-col :span="3">
            <a-dropdown :trigger="['click']">
              <a
                href="#"
                class="kg-navbar__menu h-16 w-full justify-center"
                @click.prevent
              >
                Kategori
                <LazyAtomsArrowDown />
              </a>

              <template #overlay>
                <MoleculesKategoriSubMenu />
              </template>
            </a-dropdown>
          </a-col> -->

          <a-col :span="13">
            <MoleculesSearchBar />
          </a-col>

          <a-col :span="2">
            <a
              href="https://discovery.kognisi.id/"
              target="_blank"
              class="kg-navbar__menu h-16 whitespace-nowrap justify-center"
            >
              Discovery
            </a>
          </a-col>

          <template v-if="!isLoggedIn">
            <a-col :span="2">
              <a-button size="large" block @click="showSignin = 'desktop'">
                Masuk
              </a-button>
            </a-col>

            <a-col :span="2">
              <a-button
                type="primary"
                size="large"
                block
                @click="$router.push('/signup')"
                >Daftar</a-button
              >
            </a-col>
          </template>

          <template v-else>
            <a-col :span="4">
              <a-dropdown :trigger="['click']">
                <a href="#" class="kg-navbar__menu h-16" @click.prevent>
                  <a-space>
                    <a-avatar>
                      <template #icon><UserOutlined /></template>
                    </a-avatar>

                    <div
                      class="whitespace-nowrap overflow-x-hidden overflow-ellipsis"
                      style="max-width: 130px"
                    >
                      Halo,
                      {{
                        userDetail?.data?.fullName ?? userDetail?.data?.email
                      }}
                    </div>
                  </a-space>
                </a>

                <template #overlay>
                  <a-menu
                    v-model:selected-keys="currentAccountMenu"
                    class="kg-navbar__dropdown kg-navbar__dropdown-account"
                  >
                    <a-menu-item class="account-info" disabled>
                      <a-space>
                        <a-avatar>
                          <template #icon><UserOutlined /></template>
                        </a-avatar>

                        <div>
                          <div class="account-info__name">
                            {{ userDetail?.data?.fullName ?? '-' }}
                          </div>
                          <div class="account-info__email">
                            {{ userDetail?.data?.email ?? '-' }}
                          </div>
                        </div>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item
                      v-for="account in accountMenu"
                      :key="account.key"
                    >
                      <template #icon>
                        <UserOutlined v-if="account.key === 'profil'" />
                        <ReadOutlined
                          v-else-if="account.key === 'studi-saya'"
                        />
                        <HeartOutlined v-else-if="account.key === 'wishlist'" />
                        <PlayCircleOutlined
                          v-else-if="account.key === 'playlist'"
                        />
                        <TransactionOutlined
                          v-else-if="account.key === 'riwayat-transaksi'"
                        />
                        <BellOutlined
                          v-else-if="account.key === 'notifikasi'"
                        />
                      </template>
                      <div class="w-full">
                        <NuxtLink :to="account.path" class="w-full block">
                          {{ account.label }}
                        </NuxtLink>
                      </div>
                    </a-menu-item>
                    <a-menu-item class="account-logout">
                      <a
                        href="javascript:;"
                        class="w-full block"
                        @click="authStore.logout()"
                      >
                        <a-space align="center">
                          <logout-outlined />
                          Logout
                        </a-space>
                      </a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-col>
          </template>
        </a-row>
      </nav>
    </div>
  </header>
  <client-only>
    <OrganismsSigninFormModal
      :visible="!isEmpty(showSignin)"
      :type="showSignin"
      @cancel="showSignin = undefined"
    />
  </client-only>
</template>

<style lang="scss">
@use './styles.scss';
</style>
